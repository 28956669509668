import { object } from "./feature";
import stitch from "./stitch";
export default function (topology) {
  return object(topology, meshArcs.apply(this, arguments));
}
export function meshArcs(topology, object, filter) {
  var arcs, i, n;
  if (arguments.length > 1) arcs = extractArcs(topology, object, filter);else for (i = 0, arcs = new Array(n = topology.arcs.length); i < n; ++i) arcs[i] = i;
  return {
    type: "MultiLineString",
    arcs: stitch(topology, arcs)
  };
}
function extractArcs(topology, object, filter) {
  var arcs = [],
    geomsByArc = [],
    geom;
  function extract0(i) {
    var j = i < 0 ? ~i : i;
    (geomsByArc[j] || (geomsByArc[j] = [])).push({
      i: i,
      g: geom
    });
  }
  function extract1(arcs) {
    arcs.forEach(extract0);
  }
  function extract2(arcs) {
    arcs.forEach(extract1);
  }
  function extract3(arcs) {
    arcs.forEach(extract2);
  }
  function geometry(o) {
    switch (geom = o, o.type) {
      case "GeometryCollection":
        o.geometries.forEach(geometry);
        break;
      case "LineString":
        extract1(o.arcs);
        break;
      case "MultiLineString":
      case "Polygon":
        extract2(o.arcs);
        break;
      case "MultiPolygon":
        extract3(o.arcs);
        break;
    }
  }
  geometry(object);
  geomsByArc.forEach(filter == null ? function (geoms) {
    arcs.push(geoms[0].i);
  } : function (geoms) {
    if (filter(geoms[0].g, geoms[geoms.length - 1].g)) arcs.push(geoms[0].i);
  });
  return arcs;
}