function compare(a, b) {
  return a[1][2] - b[1][2];
}
export default function () {
  var heap = {},
    array = [],
    size = 0;
  heap.push = function (object) {
    up(array[object._ = size] = object, size++);
    return size;
  };
  heap.pop = function () {
    if (size <= 0) return;
    var removed = array[0],
      object;
    if (--size > 0) object = array[size], down(array[object._ = 0] = object, 0);
    return removed;
  };
  heap.remove = function (removed) {
    var i = removed._,
      object;
    if (array[i] !== removed) return; // invalid request
    if (i !== --size) object = array[size], (compare(object, removed) < 0 ? up : down)(array[object._ = i] = object, i);
    return i;
  };
  function up(object, i) {
    while (i > 0) {
      var j = (i + 1 >> 1) - 1,
        parent = array[j];
      if (compare(object, parent) >= 0) break;
      array[parent._ = i] = parent;
      array[object._ = i = j] = object;
    }
  }
  function down(object, i) {
    while (true) {
      var r = i + 1 << 1,
        l = r - 1,
        j = i,
        child = array[j];
      if (l < size && compare(array[l], child) < 0) child = array[j = l];
      if (r < size && compare(array[r], child) < 0) child = array[j = r];
      if (j === i) break;
      array[child._ = i] = child;
      array[object._ = i = j] = object;
    }
  }
  return heap;
}