export default function (objects, bbox, n) {
  var x0 = bbox[0],
    y0 = bbox[1],
    x1 = bbox[2],
    y1 = bbox[3],
    kx = x1 - x0 ? (n - 1) / (x1 - x0) : 1,
    ky = y1 - y0 ? (n - 1) / (y1 - y0) : 1;
  function quantizePoint(input) {
    return [Math.round((input[0] - x0) * kx), Math.round((input[1] - y0) * ky)];
  }
  function quantizePoints(input, m) {
    var i = -1,
      j = 0,
      n = input.length,
      output = new Array(n),
      // pessimistic
      pi,
      px,
      py,
      x,
      y;
    while (++i < n) {
      pi = input[i];
      x = Math.round((pi[0] - x0) * kx);
      y = Math.round((pi[1] - y0) * ky);
      if (x !== px || y !== py) output[j++] = [px = x, py = y]; // non-coincident points
    }
    output.length = j;
    while (j < m) j = output.push([output[0][0], output[0][1]]);
    return output;
  }
  function quantizeLine(input) {
    return quantizePoints(input, 2);
  }
  function quantizeRing(input) {
    return quantizePoints(input, 4);
  }
  function quantizePolygon(input) {
    return input.map(quantizeRing);
  }
  function quantizeGeometry(o) {
    if (o != null && quantizeGeometryType.hasOwnProperty(o.type)) quantizeGeometryType[o.type](o);
  }
  var quantizeGeometryType = {
    GeometryCollection: function (o) {
      o.geometries.forEach(quantizeGeometry);
    },
    Point: function (o) {
      o.coordinates = quantizePoint(o.coordinates);
    },
    MultiPoint: function (o) {
      o.coordinates = o.coordinates.map(quantizePoint);
    },
    LineString: function (o) {
      o.arcs = quantizeLine(o.arcs);
    },
    MultiLineString: function (o) {
      o.arcs = o.arcs.map(quantizeLine);
    },
    Polygon: function (o) {
      o.arcs = quantizePolygon(o.arcs);
    },
    MultiPolygon: function (o) {
      o.arcs = o.arcs.map(quantizePolygon);
    }
  };
  for (var key in objects) {
    quantizeGeometry(objects[key]);
  }
  return {
    scale: [1 / kx, 1 / ky],
    translate: [x0, y0]
  };
}