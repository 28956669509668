import identity from "./identity";
export default function (transform) {
  if (transform == null) return identity;
  var x0,
    y0,
    kx = transform.scale[0],
    ky = transform.scale[1],
    dx = transform.translate[0],
    dy = transform.translate[1];
  return function (input, i) {
    if (!i) x0 = y0 = 0;
    var j = 2,
      n = input.length,
      output = new Array(n),
      x1 = Math.round((input[0] - dx) / kx),
      y1 = Math.round((input[1] - dy) / ky);
    output[0] = x1 - x0, x0 = x1;
    output[1] = y1 - y0, y0 = y1;
    while (j < n) output[j] = input[j], ++j;
    return output;
  };
}