export default function (topology, p) {
  var array = [];
  topology.arcs.forEach(function (arc) {
    arc.forEach(function (point) {
      if (isFinite(point[2])) {
        // Ignore endpoints, whose weight is Infinity.
        array.push(point[2]);
      }
    });
  });
  return array.length && quantile(array.sort(descending), p);
}
function quantile(array, p) {
  if (!(n = array.length)) return;
  if ((p = +p) <= 0 || n < 2) return array[0];
  if (p >= 1) return array[n - 1];
  var n,
    h = (n - 1) * p,
    i = Math.floor(h),
    a = array[i],
    b = array[i + 1];
  return a + (b - a) * (h - i);
}
function descending(a, b) {
  return b - a;
}