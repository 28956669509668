import join from "./join";

// Given an extracted (pre-)topology, cuts (or rotates) arcs so that all shared
// point sequences are identified. The topology can then be subsequently deduped
// to remove exact duplicate arcs.
export default function (topology) {
  var junctions = join(topology),
    coordinates = topology.coordinates,
    lines = topology.lines,
    rings = topology.rings,
    next,
    i,
    n;
  for (i = 0, n = lines.length; i < n; ++i) {
    var line = lines[i],
      lineMid = line[0],
      lineEnd = line[1];
    while (++lineMid < lineEnd) {
      if (junctions.has(coordinates[lineMid])) {
        next = {
          0: lineMid,
          1: line[1]
        };
        line[1] = lineMid;
        line = line.next = next;
      }
    }
  }
  for (i = 0, n = rings.length; i < n; ++i) {
    var ring = rings[i],
      ringStart = ring[0],
      ringMid = ringStart,
      ringEnd = ring[1],
      ringFixed = junctions.has(coordinates[ringStart]);
    while (++ringMid < ringEnd) {
      if (junctions.has(coordinates[ringMid])) {
        if (ringFixed) {
          next = {
            0: ringMid,
            1: ring[1]
          };
          ring[1] = ringMid;
          ring = ring.next = next;
        } else {
          // For the first junction, we can rotate rather than cut.
          rotateArray(coordinates, ringStart, ringEnd, ringEnd - ringMid);
          coordinates[ringEnd] = coordinates[ringStart];
          ringFixed = true;
          ringMid = ringStart; // restart; we may have skipped junctions
        }
      }
    }
  }
  return topology;
}
function rotateArray(array, start, end, offset) {
  reverse(array, start, end);
  reverse(array, start, start + offset);
  reverse(array, start + offset, end);
}
function reverse(array, start, end) {
  for (var mid = start + (end-- - start >> 1), t; start < mid; ++start, --end) {
    t = array[start], array[start] = array[end], array[end] = t;
  }
}