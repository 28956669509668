import prune from "./prune";
export default function (topology, filter) {
  var oldObjects = topology.objects,
    newObjects = {},
    key;
  if (filter == null) filter = filterTrue;
  function filterGeometry(input) {
    var output, arcs;
    switch (input.type) {
      case "Polygon":
        {
          arcs = filterRings(input.arcs);
          output = arcs ? {
            type: "Polygon",
            arcs: arcs
          } : {
            type: null
          };
          break;
        }
      case "MultiPolygon":
        {
          arcs = input.arcs.map(filterRings).filter(filterIdentity);
          output = arcs.length ? {
            type: "MultiPolygon",
            arcs: arcs
          } : {
            type: null
          };
          break;
        }
      case "GeometryCollection":
        {
          arcs = input.geometries.map(filterGeometry).filter(filterNotNull);
          output = arcs.length ? {
            type: "GeometryCollection",
            geometries: arcs
          } : {
            type: null
          };
          break;
        }
      default:
        return input;
    }
    if (input.id != null) output.id = input.id;
    if (input.bbox != null) output.bbox = input.bbox;
    if (input.properties != null) output.properties = input.properties;
    return output;
  }
  function filterRings(arcs) {
    return arcs.length && filterExteriorRing(arcs[0]) // if the exterior is small, ignore any holes
    ? [arcs[0]].concat(arcs.slice(1).filter(filterInteriorRing)) : null;
  }
  function filterExteriorRing(ring) {
    return filter(ring, false);
  }
  function filterInteriorRing(ring) {
    return filter(ring, true);
  }
  for (key in oldObjects) {
    newObjects[key] = filterGeometry(oldObjects[key]);
  }
  return prune({
    type: "Topology",
    bbox: topology.bbox,
    transform: topology.transform,
    objects: newObjects,
    arcs: topology.arcs
  });
}
function filterTrue() {
  return true;
}
function filterIdentity(x) {
  return x;
}
function filterNotNull(geometry) {
  return geometry.type != null;
}