export default function (topology) {
  var oldObjects = topology.objects,
    newObjects = {},
    oldArcs = topology.arcs,
    oldArcsLength = oldArcs.length,
    oldIndex = -1,
    newIndexByOldIndex = new Array(oldArcsLength),
    newArcsLength = 0,
    newArcs,
    newIndex = -1,
    key;
  function scanGeometry(input) {
    switch (input.type) {
      case "GeometryCollection":
        input.geometries.forEach(scanGeometry);
        break;
      case "LineString":
        scanArcs(input.arcs);
        break;
      case "MultiLineString":
        input.arcs.forEach(scanArcs);
        break;
      case "Polygon":
        input.arcs.forEach(scanArcs);
        break;
      case "MultiPolygon":
        input.arcs.forEach(scanMultiArcs);
        break;
    }
  }
  function scanArc(index) {
    if (index < 0) index = ~index;
    if (!newIndexByOldIndex[index]) newIndexByOldIndex[index] = 1, ++newArcsLength;
  }
  function scanArcs(arcs) {
    arcs.forEach(scanArc);
  }
  function scanMultiArcs(arcs) {
    arcs.forEach(scanArcs);
  }
  function reindexGeometry(input) {
    var output;
    switch (input.type) {
      case "GeometryCollection":
        output = {
          type: "GeometryCollection",
          geometries: input.geometries.map(reindexGeometry)
        };
        break;
      case "LineString":
        output = {
          type: "LineString",
          arcs: reindexArcs(input.arcs)
        };
        break;
      case "MultiLineString":
        output = {
          type: "MultiLineString",
          arcs: input.arcs.map(reindexArcs)
        };
        break;
      case "Polygon":
        output = {
          type: "Polygon",
          arcs: input.arcs.map(reindexArcs)
        };
        break;
      case "MultiPolygon":
        output = {
          type: "MultiPolygon",
          arcs: input.arcs.map(reindexMultiArcs)
        };
        break;
      default:
        return input;
    }
    if (input.id != null) output.id = input.id;
    if (input.bbox != null) output.bbox = input.bbox;
    if (input.properties != null) output.properties = input.properties;
    return output;
  }
  function reindexArc(oldIndex) {
    return oldIndex < 0 ? ~newIndexByOldIndex[~oldIndex] : newIndexByOldIndex[oldIndex];
  }
  function reindexArcs(arcs) {
    return arcs.map(reindexArc);
  }
  function reindexMultiArcs(arcs) {
    return arcs.map(reindexArcs);
  }
  for (key in oldObjects) {
    scanGeometry(oldObjects[key]);
  }
  newArcs = new Array(newArcsLength);
  while (++oldIndex < oldArcsLength) {
    if (newIndexByOldIndex[oldIndex]) {
      newIndexByOldIndex[oldIndex] = ++newIndex;
      newArcs[newIndex] = oldArcs[oldIndex];
    }
  }
  for (key in oldObjects) {
    newObjects[key] = reindexGeometry(oldObjects[key]);
  }
  return {
    type: "Topology",
    bbox: topology.bbox,
    transform: topology.transform,
    objects: newObjects,
    arcs: newArcs
  };
}