export function planarTriangleArea(triangle) {
  var a = triangle[0],
    b = triangle[1],
    c = triangle[2];
  return Math.abs((a[0] - c[0]) * (b[1] - a[1]) - (a[0] - b[0]) * (c[1] - a[1])) / 2;
}
export function planarRingArea(ring) {
  var i = -1,
    n = ring.length,
    a,
    b = ring[n - 1],
    area = 0;
  while (++i < n) a = b, b = ring[i], area += a[0] * b[1] - a[1] * b[0];
  return Math.abs(area) / 2;
}