import transform from "./transform";
export default function (topology) {
  var t = transform(topology.transform),
    key,
    x0 = Infinity,
    y0 = x0,
    x1 = -x0,
    y1 = -x0;
  function bboxPoint(p) {
    p = t(p);
    if (p[0] < x0) x0 = p[0];
    if (p[0] > x1) x1 = p[0];
    if (p[1] < y0) y0 = p[1];
    if (p[1] > y1) y1 = p[1];
  }
  function bboxGeometry(o) {
    switch (o.type) {
      case "GeometryCollection":
        o.geometries.forEach(bboxGeometry);
        break;
      case "Point":
        bboxPoint(o.coordinates);
        break;
      case "MultiPoint":
        o.coordinates.forEach(bboxPoint);
        break;
    }
  }
  topology.arcs.forEach(function (arc) {
    var i = -1,
      n = arc.length,
      p;
    while (++i < n) {
      p = t(arc[i], i);
      if (p[0] < x0) x0 = p[0];
      if (p[0] > x1) x1 = p[0];
      if (p[1] < y0) y0 = p[1];
      if (p[1] > y1) y1 = p[1];
    }
  });
  for (key in topology.objects) {
    bboxGeometry(topology.objects[key]);
  }
  return [x0, y0, x1, y1];
}