export default function (topology) {
  var ownerByArc = new Array(topology.arcs.length),
    // arc index -> index of unique associated ring, or -1 if used by multiple rings
    ownerIndex = 0,
    key;
  function testGeometry(o) {
    switch (o.type) {
      case "GeometryCollection":
        o.geometries.forEach(testGeometry);
        break;
      case "Polygon":
        testArcs(o.arcs);
        break;
      case "MultiPolygon":
        o.arcs.forEach(testArcs);
        break;
    }
  }
  function testArcs(arcs) {
    for (var i = 0, n = arcs.length; i < n; ++i, ++ownerIndex) {
      for (var ring = arcs[i], j = 0, m = ring.length; j < m; ++j) {
        var arc = ring[j];
        if (arc < 0) arc = ~arc;
        var owner = ownerByArc[arc];
        if (owner == null) ownerByArc[arc] = ownerIndex;else if (owner !== ownerIndex) ownerByArc[arc] = -1;
      }
    }
  }
  for (key in topology.objects) {
    testGeometry(topology.objects[key]);
  }
  return function (ring) {
    for (var j = 0, m = ring.length, arc; j < m; ++j) {
      if (ownerByArc[(arc = ring[j]) < 0 ? ~arc : arc] === -1) {
        return true;
      }
    }
    return false;
  };
}